import React, { useState, useEffect } from 'react';

export const LoadingComponent = () => {
	const [loadingPercentage, setLoadingPercentage] = useState(0);

	useEffect(() => {
		const loadingInterval = setInterval(() => {
			setLoadingPercentage((prevPercentage) => {
				if (prevPercentage >= 100) {
					clearInterval(loadingInterval);
					return 100;
				}
				return prevPercentage + 1;
			});
		}, 1800); // 180,000ms (2min) / 100 increments

		return () => clearInterval(loadingInterval);
	}, []);

	return (
		<div className="loading">
			<p>Loading: {loadingPercentage}%</p>
			{/* <div className='progress-container'>
				<progress value={loadingPercentage} max="100" />
			</div> */}
		</div>
	);
};
