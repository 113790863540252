import React from 'react';

// Components
import { LoadingComponent } from '../components/Loading';

// Helpers
import { clearLocalStoreValue } from '../helpers/LocalStore';

// Icons
import { ReactComponent as IconFace } from '../assets/icons/ui/face_nc.svg';
import { ReactComponent as IconShare } from '../assets/icons/ui/share_nc.svg';
import { ReactComponent as IconMenu } from '../assets/icons/ui/menu.svg';

// Read JSON Data
const geninfo = require('../data/geninfo.json').data;
const initialAuthors = require('../data/authors.json').authors;

export const Header = ({
	user,
	avatar,
	authorList,
	setUser,
	setAvatar,
	setOpenMenu,
	setUserAdmin,
	setAuthorFiltered,
	setOpenLogin,
	setOpenShare,
	setOpenQuoteForm,
	setOpenAuthorForm,
	setOpenRegisterForm,
	setUserAcceptCookies,
	setOpenEditUserForm,
}) => {
	const handleSelector = (name) => {
		// console.log(name);
		name !== 'all' ? setAuthorFiltered(name) : setAuthorFiltered('all');
	};

	const logoId = authorList.length >= 10 ? 'logo' : 'logo-init';

	const handleLogin = () => {
		setOpenLogin(true);
		toggleFullScreen();
	};

	const handleShare = () => {
		setOpenShare(true);
	};

	const handleLogout = () => {
		setUser('');
		setAvatar('');
		setUserAdmin(false);
		setUserAcceptCookies(false);
		clearLocalStoreValue('auth-ID');
	};

	const handleAuthorRegister = () => {
		if (user) {
			setOpenAuthorForm(true);
		} else {
			setOpenLogin(true);
		}
	};

	const handleQuoteRegister = () => {
		if (user) {
			setOpenQuoteForm(true);
		} else {
			setOpenLogin(true);
		}
	};

	const handleRegister = () => {
		setOpenRegisterForm(true);
	};

	const handleEditUser = () => {
		setOpenEditUserForm(true);
	};

	const handleMenu = () => {
		setOpenMenu(true);
		toggleFullScreen();
	};

	function toggleFullScreen() {
		if (!document.fullscreenElement) {
			document.documentElement.requestFullscreen();
		}
	}

	return (
		<div>
			<div className="component_header-line line1">
				<h3>
					<a
						className="logo"
						id={logoId}
						href={geninfo[0].webpage}
						target="_blank"
						rel="noreferrer"
					>
						<i>Quotes for life</i>
					</a>
				</h3>
				{authorList.length >= 10 ? (
					<div className="line-items">
						{user ? (
							<button className="login" onClick={handleLogout}>
								Log out
							</button>
						) : (
							<button className="login" onClick={handleLogin}>
								Log in
							</button>
						)}
						{!user && (
							<button className="register" onClick={handleRegister}>
								Sign up
							</button>
						)}
						<button className="icon" onClick={handleMenu}>
							<IconMenu />
						</button>
						{avatar ? (
							<img
								onClick={() => handleEditUser()}
								className="elements_image-avatar_header"
								src={avatar}
								alt="avatar"
							/>
						) : (
							<p onClick={() => handleEditUser()} className="icon">
								{user && <IconFace />}
							</p>
						)}
					</div>
				) : (
					// <p className="loading">Loading...</p>
					<LoadingComponent />
				)}
			</div>
			<div className="component_header-line line2">
				{authorList.length >= 10 && (
					<div className="line-items">
						<select
							id="author"
							name="author"
							className="custom-select"
							onChange={(e) => handleSelector(e.target.value, 'author')}
						>
							<option value="all">Select Author</option>
							<option value="all">All Authors</option>

							{authorList.length >= 10
								? authorList.map((name) => (
										<option key={name._id} value={name.name}>
											{name.name}
										</option>
								  ))
								: initialAuthors.map((name) => (
										<option key={name._id} value={name.name}>
											{name.name}
										</option>
								  ))}
						</select>
					</div>
				)}
				<div className="line-items">
					{/* <button className='quote-register' onClick={handleShare}>
						<span className='icon'>
							<IconShare />
						</span>
					</button> */}
					{authorList.length >= 10 && (
						<>
							<button
								className="author-register"
								onClick={handleAuthorRegister}
							>
								Add Author
							</button>
							<button className="quote-register" onClick={handleQuoteRegister}>
								Add Quote
							</button>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
